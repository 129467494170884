<template>
  <div v-if='alertState' class="alert-container">
    <div class="alert-content-layout">
      <div class="alert-content-container" :class="alertType">
        <div class="flex items-center px-4 py-2">
          <div id="alert-component-icon" :class="alertClass" class="icon w-6 h-6" />
          <div class="alert-content text-14px mx-2">
            <div class="text_bold italic">
              {{ alertMsg }}
            </div>
          </div>
          <button id="alert-component-close-btn" @click.prevent="closeAlert" class="alert-close-btn icon close w-6 h-6">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    props: ['alertState', 'alertType','alertMsg'],
    methods: {
      closeAlert() {
        this.$emit('closeAlert');
      },
      hideAlert() {
        this.$emit('hideAlert');
      },
    },
    computed: {
      alertClass: function() {
        return `alert-${this.alertType}`;
      }
    }
  })
</script>
