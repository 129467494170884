<template>
  <div class="flex justify-between mt-4 text-14px p-1 rounded-8px bg-grey-2">
    <div class="flex items-center">
      <button v-for="type in stateTypes" :key="type.type"
              @click="$emit('filter', type.type)"
              :class="['flex items-center px-2 py-1 mr-1 font-medium rounded-8px hover:bg-grey-1', 
                     { 'bg-grey-1': type.type === currentType }]">
        <div class="text-[13.5px]">
          {{ $t(`post.state.${type.type}`) }}
        </div>
        <div class="inline-flex ml-2 px-1 h-7 min-w-[32px] rounded-[20px] justify-center items-center relative"
             :style="{ background: `var(--color-post-state-${type.type.replace(/_/g, '-')})` }">
          {{ type.count }}
          <div v-if="type.unread_count > 0" class="absolute unread-point -top-1 -right-1 w-3 h-3 bg-primary rounded-full"></div>
        </div>
      </button>
    </div>
    <a
      href="/profile/sorted_models"
      class="border rounded-[16px] px-1.5 py-1 inline-block leading-[26px] text-[12px] hover:bg-[#5c5c5c]"
    >
    {{ $t(`post.state.shop_sort`) }}
    </a>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    stateTypes: {
      type: Array,
      required: true
    },
    currentState: {
      type: String,
      required: true
    }
  },
  emits: ['filter'],
  setup(props) {
    const currentType = computed(() => props.currentState);

    return {
      currentType
    };
  }
};

</script>
