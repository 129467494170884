import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import ProfileModels from '../../vue/pages/ProfileModels.vue';
import ModelSearchForm from "./components/ModelSearchForm.vue";
import ModelStateFilter from "./components/ModelStateFilter.vue";
import ModelItem from "./components/ModelItem.vue";
import NavPanel from "./components/NavPanel.vue";

class ProfileModelsComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }
  init() {
    this.app = createApp(ProfileModels);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('model-search-form', ModelSearchForm);
    this.app.component('model-state-filter', ModelStateFilter);
    this.app.component('model-item', ModelItem);
    this.app.component('nav-panel', NavPanel);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default ProfileModelsComponent;
