<template>
  <div class="modal-container">
    <div class="modal-black-cover"></div>
    <div class="modal-content-layout">
      <div class="modal-content-container p-6 pt-10 justify-center">
        <h2
          class="text-20px md:text-24px leading-tight font-bold text-center md:min-w-[400px]"
        >
          {{ $t('post_report.title') }}
        </h2>
        <hr class="mx-auto mt-2 mb-4 md:mb-6 border-t-0 w-16 h-1 bg-primary" />

        <div class="report-container flex flex-col">
          <div class="report-options flex flex-col mx-auto mb-4">
            <template v-for="opt in options">
              <label
                :for="opt.key"
                @click.prevent="optionChange(opt.key, opt.reason)"
                class="flex items-center py-2 text-lg"
              >
                <input
                  :id="opt.key"
                  class="custom-input"
                  type="radio"
                  name="category"
                  :value="opt.key"
                />
                <span class="custom__check mr-2"></span>
                {{ opt.reason }}
              </label>
            </template>
          </div>
          <div class="report-hint flex flex-col mx-auto mb-4 max-w-[300px] text-center">
            {{ $t('post_report.hint') }}
          </div>
          <div class="grid grid-cols-2 space-x-2">
            <a class="btn btn-secondary" @click.prevent="closeModal">
              {{ $t('post_report.cancel') }}
            </a>
            <button
              class="btn btn-primary"
              @click.prevent="submitReportComment"
              :disabled="!reportReason"
            >
              {{ $t('post_report.submit') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { state, reportState } from "../stores/postState";

export default defineComponent({
  data() {
    return {
      options: [
        {
          key: 'model_damage',
          reason: this.$t('post_report.model_damage'),
        },
        {
          key: 'incorrect_file',
          reason: this.$t('post_report.incorrect_file'),
        },
        {
          key: 'incomplete_file',
          reason: this.$t('post_report.incomplete_file'),
        },
        {
          key: 'offensive_or_abusive',
          reason: this.$t('post_report.offensive_or_abusive'),
        },
        {
          key: 'duplicate_uploads',
          reason: this.$t('post_report.duplicate_uploads'),
        }
      ],
      state,
      reportState
    }
  },
  props: ['reportReason'],
  methods: {
    closeModal() {
      this.reportState.openModal = false
      this.reportState.targetId = null
      this.reportState.targetType = null
      this.reportState.reportKey = null
      this.reportState.reportReason = null
    },
    optionChange(key, reason){
      this.reportState.reportKey = key
      this.reportState.reportReason = reason
    },
    submitReportComment() {
      if (!this.reportState.reportKey || !this.reportState.reportReason) {
        alert(this.$t('report_model.report_warning'))
        return
      }

      const formData = new FormData()
      formData.append('category', this.reportState.reportKey)

      fetch(`/api/v1/posts/${this.reportState.targetId}/post_report`, {
        method: 'post',
        headers: {
          "X-CSRF-Token": this.state.CSRFtoken
        },
        body: formData
      })
      .then((res) => {
        if (res.ok) {
          alert(this.$t('report_model.success'))
        } else {
          alert(this.$t('report_model.duplicate'))
        }
      })
      .then((data) => {
        this.closeModal()
      })
    }
  },
});
</script>
../stores/postState