<template>
  <div class='w-account-container'>
    <div class='flex items-center text-18px'>
      <div id='post-comments-tab' class='nav'>
        {{ $t('post_comments_collection.comments') }}
        <span class="px-1">
          ({{commentState.commentCount}})
        </span>
      </div>
    </div>
    <div class="px-4 pb-4">
      <comment-form :fetchCommentItemData='fetchCommentItemData'
                    :key="`${commentState.edit_target?.id}+${commentState.default_stars}`"
                    :default_stars='commentState.default_stars'>
      </comment-form>

      <div v-if='commentState.comment_items.length > 0' id='post-comments' class='-mb-4'>
        <hr class="border-grey-5 my-4">

        <div class='comment-header'>
          <select class="default-input-klass" data-uniform-select="true" v-on:change="selectCommentType">
            <option value='0'>
              {{ $t('post_comments_collection.all') }}
            </option>
            <option value='PostComment'>
              {{ $t('post_comments_collection.post_comments') }}
            </option>
            <option value='PostReview'>
              {{ $t('post_comments_collection.post_reviews') }}
            </option>
          </select>

          <div class='comment-order-type'>
            <div class="icon"></div>
            <input type="radio" name='comment-order-type' class='hidden' id='order_type_most_like'>
            <label for='order_type_most_like' @click="change_comment_order_type('most_like')">
              {{ $t('post_comments_collection.top') }}
            </label>
            <span>|</span>
            <input type="radio" name='comment-order-type' class='hidden' id='order_type_latest' checked>
            <label for='order_type_latest' @click="change_comment_order_type('latest')">
              {{ $t('post_comments_collection.latest') }}

            </label>
          </div>
        </div>

        <template
          v-for="comment in commentState.selected_comment_items"
           :key="comment.id + comment.updated_at">
          <post-comment :comment='comment' :fetchCommentItemData='fetchCommentItemData' :commentState='commentState'></post-comment>
        </template>
      </div>
    </div>
  </div>

  <report-comment-modal v-if='reportState.openModal' :reportReason='reportState.reportReason'></report-comment-modal>
</template>

<script>
  import { defineComponent } from "vue";
  import { state, commentState, reportState, slideState } from "../stores/postState";

  export default defineComponent({
    data() {
      return {
        state,
        commentState,
        slideState,
        reportState,
        scrolled: false,
      };
    },
    mounted() {
      // set post_id
      this.state.post_id = window.location.href.match(/\/posts\/(\d+)/)[1]
      // rspec 沒有 csrf
      if(document.querySelector('meta[name="csrf-token"]')) {
        this.state.CSRFtoken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }

      if(this.state.post_id) {
        this.fetchCommentItemData()
      }
    },
    updated(){
      window.initUniformSelect()

      if (!this.scrolled) {
        const target = this.getCurrentURLPrefixAndId()
        if (!target || !target['id']) {
          this.scrolled = true
          return;
        }

        this.scrollToTarget(target);
      }
    },
    methods: {
      change_comment_order_type(type) {
        if (this.commentState.order_type == type) {
          return
        }

        this.commentState.order_type = type
        this.fetchCommentItemData()
      },
      selectCommentType(e){
        let selected_data = []

        if (e.target.value != 0) {
          this.commentState.comment_items.forEach(function(data){
            if (data.type == e.target.value) {
              selected_data.push(data)
            }
          })
        } else {
          selected_data = this.commentState.comment_items
        }

        this.commentState.selected_comment_items = selected_data
      },
      getCurrentURLPrefixAndId() {
        const url = window.location.href;
        const match = url.match(/#(comment|new-review)-(\d+)$/);
        if (match) {
          return {
            prefix: match[1],
            id: match[2]
          };
        }
        return null;
      },
      scrollToTarget(target) {
        let targetElement
        if (target['prefix'] == 'new-review') {
          targetElement = document.getElementById('post-comment-form')
          this.commentState.default_stars = target['id']
        } else {
          targetElement = document.getElementById(`${target['prefix']}-${target['id']}`)
        }

        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          this.scrolled = true
        }
      },
      async fetchCommentItemData() {
        try {
          const api_path = '/api/v1/posts/' + this.state.post_id + '/comments?o=' + this.commentState.order_type
          const response = await fetch(api_path);
          if (response.ok) {
            const data = await response.json();
            const itemsData = data['data']

            this.state.post_profile = data['post_profile']
            this.state.curr_profile = data['current_profile']

            this.commentState.reviewable = data['reviewable']
            this.commentState.has_review = data['has_review']

            this.commentState.comment_items = [...itemsData];
            this.commentState.commentCount = data['comment_count'];
            this.commentState.selected_comment_items = [...itemsData];
          }
        } catch (error) {
          console.error('Error:', error);

          this.commentState.comment_items = [];
          this.commentState.selected_comment_items = [];
        }
      }
    }
  })
</script>