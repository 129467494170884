import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import PostImageSlideCollection from './layouts/PostImageSlideCollection.vue';
import PostImageFullScreenSlide from './components/PostImageFullScreenSlide.vue';

class PostImageSlideComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }

  // Initialize the component
  init() {
    this.app = createApp(PostImageSlideCollection);
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });
    
    this.app.component('post-image-full-screen-slide', PostImageFullScreenSlide);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default PostImageSlideComponent;
