<template>
  <div class="mb-2">
    <div class="grid rounded-8px" 
        :class="[
                isPinned ? 'bg-[#525964]' : 'bg-grey-2',
                orderType === 'custom_order' 
                  ? (isSortable ? 'grid-cols-[144px_1fr_128px]' : 'grid-cols-[92px_1fr_128px]') 
                  : 'grid-cols-[72px_1fr_128px]'
                ]">
      <div class='p-2 flex justify-between items-center'>
        <img v-if="orderType === 'custom_order' && isSortable" src="../../../images/icons/sortable_icon.svg" class="w-8 h-8 object-contain sortable-handle cursor-move"/>
        <div v-if="orderType === 'custom_order'">{{ index }}</div>
        <div class="w-14 h-14 rounded-8px overflow-hidden relative">
          <div v-if="post.phrozen_verified" class="w-10 h-4 flex items-center justify-center absolute left-1/2 bottom-[15%] translate-x-[-50%] bg-red rounded text-14px bg-[#E60044]">
            <img src="../../../images/home/phrozen_verified_white.svg" class="w-3 h-3 object-contain"/>
              PV
          </div>
          <div v-if="post.price == 0" class="w-10 h-4 flex items-center justify-center absolute left-1/2 bottom-[15%] translate-x-[-50%] rounded text-14px text-[#19791D] bg-[#2CFF67]">
            {{ $t('post_item.free') }}
          </div>
          <img :src="post.cover_image_src" :alt="post.title" />
        </div>
      </div>
      <div class='py-2'>
        <div class="flex flex-col justify-between h-full pr-3 break-word">
          <div class="post_title text-14px font-medium hover:underline">
            <a :href="post.visible ? `/posts/${post.id}` : `/posts/${post.id}/preview`" class="">
              {{ post.title }}
            </a>
          </div>
          <div class="timestamp text-12px text-grey-5">
            <div class="created">
              Create: {{ formatDate(post.created_at) }}
            </div>
            <div class="price">
              Price: {{ formatCurrency(post.price) }}
            </div>
          </div>
        </div>
      </div>
      <div class='py-2 pr-2 flex justify-center items-center'>
        <a v-if="orderType === 'custom_order'" @click.prevent="$emit('moveToTop', post.id)" class="group relative flex justify-center items-center w-6 h-6 m-1.5 cursor-pointer to-top">
          <img src="../../../images/icons/upload2.svg" class="object-contain"/>
          <div class="tooltip-top invisible group-hover:visible absolute bg-grey-1 text-white text-xs py-1 px-2 rounded whitespace-nowrap top-full left-1/2 -translate-x-1/2 mt-1">
            {{ $t('models.index.to_top') }}
          </div>
        </a>
        <a v-if="orderType === 'custom_order'" @click.prevent="$emit('moveToBottom', post.id)" class="group relative flex justify-center items-center w-4.5 h-4.5 m-1.5 cursor-pointer to-bottom">
          <img src="../../../images/icons/download.svg" class="object-contain"/>
          <div class="tooltip-bottom invisible group-hover:visible absolute bg-grey-1 text-white text-xs py-1 px-2 rounded whitespace-nowrap top-[116%] left-1/2 -translate-x-1/2 mt-1">
            {{ $t('models.index.to_bottom') }}
          </div>
        </a>
        <a v-if="showPinIcon" @click.prevent="$emit('moveToPin', post.id)" class="flex justify-center items-center w-6 h-6 m-1.5 cursor-pointer hover:brightness-[110%] hover:contrast-[95%]">
          <img :src="isPinned ? require('../../../images/icons/icon_star_yellow.svg') : require('../../../images/icons/icon_star_yellow_empty.svg')" class="object-contain"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    post: {
      type: Object,
      required: true
    },
    isPinned: {
      type: Boolean,
      default: false
    },
    isSortable: {
      type: Boolean,
      default: false
    },
    showPinIcon: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    orderType: {
      type: String,
      required: true
    }
  },
  emits: ['moveToTop', 'moveToBottom', 'moveToPin'],
  setup() {
    const formatDate = (date) => {
      return new Date(date).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
    };

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
    };

    return {
      formatDate,
      formatCurrency
    };
  }
});
</script>