<template>
  <div v-if="modalState" :class="['modal-container']">
    <div class="modal-black-cover"></div>
    <div class="modal-content-layout">
      <div class="modal-content-container request-model overflow-auto px-6">
        <div class="flex flex-col">
          <form method="put">
            <h2
              class="text-20px md:text-24px mt-10 leading-tight font-bold text-center"
            >
              {{ $t("request_model.title") }}
            </h2>
            <hr
              class="mx-auto mt-2 mb-4 md:mb-6 border-t-0 w-16 h-1 bg-primary"
            />
            <div class="flex flex-col mx-6">
              <div class="relative flex flex-col text-center mb-8 mx-6">
                <div class="flex flex-col mt-4 w-full relative">
                  <label for="description" class="font-medium text-left">
                    {{ $t("request_model.model_description") }}
                    <span class="text-orange-20 align-text-top">*</span>
                  </label>
                  <div class="absolute right-2 text-gray-20 text-12px font-medium">
                    {{ modelDescriptionCount }}
                  </div>
                  <textarea
                    name="description"
                    v-model="model_description"
                    maxlength="500"
                    class="focus-color bg-grey-2 border rounded-lg h-40 p-2"
                  ></textarea>
                </div>

                <div class="flex flex-col mt-4 w-full relative">
                  <label for="recommended" class="font-medium text-left">
                    {{ $t("request_model.recommended_creators") }}
                  </label>
                  <div class="absolute right-2 text-gray-20 text-12px font-medium">
                    {{ recommendedCreatorsCount }}
                  </div>
                  <input
                    type="text"
                    name="recommended"
                    v-model="recommended_creators"
                    maxlength="50"
                    class="focus-color bg-grey-2 border rounded-lg h-10 px-2"
                  />
                </div>

                <div class="flex text-12px text-left my-4 w-full">
                  {{ $t("request_model.notify") }}
                </div>

                <div class="grid grid-cols-2 space-x-2">
                  <a class="btn btn-secondary" @click="toggleModal">
                    {{ $t("request_model.cancel") }}
                  </a>
                  <button
                    class="btn btn-primary"
                    @click.prevent="submitRequestModel"
                    :disabled="!model_description"
                  >
                    {{ $t("request_model.submit") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: ["modalState"],
  emits: ["alert", "close-alert", "close", "hide-alert"],
  data() {
    return {
      model_description: "",
      recommended_creators: "",
    };
  },
  methods: {
    toggleModal() {
      this.$emit("close");
    },
    submitRequestModel() {
      if (!this.model_description) {
        this.emitAlertAndClose(
          "danger",
          true,
          $t("request_model.model_description_required")
        );
        return;
      }
      const requestUrl = `/model_requests`;
      fetch(requestUrl, {
        method: "post",
        headers: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          model_request: {
            category: "model_requests",
            title: "model_requests",
            content: {
              model_description: this.model_description,
              recommended_creators: this.recommended_creators,
            },
            state: "created",
          },
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 401) {
            throw new Error("Unauthorized");
          } else {
            return res.json().then((errData) => {
              throw new Error(errData.message || "Error in submission");
            });
          }
        })
        .then((data) => {
          this.emitAlertAndClose("success", true, data.message);
          this.model_description = "";
          this.recommended_creators = "";
        })
        .catch((error) => {
          if (error.message === "Unauthorized") {
            window.location.href = "/users/sign_up";
          } else {
            this.emitAlertAndClose("danger", true, error.message);
          }
        });
    },
    emitAlertAndClose(alertType, alertState, alertMsg) {
      this.$emit("alert", { alertType, alertState, alertMsg });
      this.$emit("close");
      this.$emit("hide-alert");
    },
  },
  computed: {
    modelDescriptionCount() {
      return `${this.model_description.length} / 500`;
    },
    recommendedCreatorsCount() {
      return `${this.recommended_creators.length} / 50`;
    },
  },
});
</script>
