<template>
  <div class="w-full flex flex-wrap">
    <div class="flex-1">
      <model-search-form @search="handleSearch" :create-btn="true" />
      <model-state-filter @filter="handleStateFilter"  :current-state="state.currentState" :state-types="state.postStateTypes" />

      <div class="mt-4 text-14px">
        <div class="grid grid-cols-[33fr_47fr_75fr_36fr_16fr]">
          <div class='h-5 text-grey-3 font-medium pl-2'>{{ $t(`models.index.thumbnail`) }}</div>
          <div class='h-5 text-grey-3 font-medium'>{{ $t(`models.index.model_title`) }}</div>
          <div class='h-5 text-grey-3 font-medium'>{{ $t(`models.index.catalog`) }}</div>
          <div class='h-5 text-grey-3 font-medium text-center'>{{ $t(`models.index.status`) }}</div>
          <div class='h-5 text-grey-3 font-medium'></div>
          <hr class='col-span-5 border-grey-3 my-2'>
        </div>

        <div v-if="state.initialLoading" class="flex flex-col items-center justify-center aspect-[828/495]">
          <p class="text-16px font-medium text-center">
            {{ $t('models.index.loading') }}
          </p>
        </div>

        <div v-else-if="currentUser && currentUser.post_count === 0" class="flex flex-col items-center justify-center aspect-[828/495]">
          <img src="../../images/sad.svg" class="block w-12 h-12 mb-4" />
          <p class="text-16px font-medium text-center">
            {{ $t('models.index.no_model_now') }}
            <br>
            {{ $t('models.index.create_your_model') }}
          </p>
        </div>

        <div v-else-if="items && items.length === 0" class="flex flex-col items-center justify-center aspect-[828/495]">
          <img src="../../images/file.svg" class="block w-12 h-12 mb-4" />
          <p class="text-16px font-medium text-center">
            {{ $t('models.index.no_record_model') }}
          </p>
        </div>

        <div v-else class="aspect-[828/495]">
          <div class="model-container max-h-full h-full overflow-auto scrollbar pr-1 -mr-3"
               ref="modelContainer">
            <model-item
              v-for="(post, index) in items"
              :key="post.id"
              :post="post"
              :is-last="index === items.length - 1"
              :is-submitting="submittingPosts.has(post.id)"
              @post-submit="handleSubmitAction"
              @post-deleted="handlePostDelete"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, ref, reactive, computed, onMounted, onUpdated, nextTick} from "vue";
import debounce from 'lodash/debounce';

export default defineComponent({
  setup(props) {
    const currentUser = ref(null);
    const state = reactive({
      loading: false,
      initialLoading: true,
      itemsCount: 8,
      postStateTypes: [],
      currentSearch: '',
      currentState: 'all',
    });

    const stateData = reactive({
      all: { items: [], totalCount: 0 },
      draft: { items: [], totalCount: 0 },
      pending: { items: [], totalCount: 0 },
      published: { items: [], totalCount: 0 },
      submit_rejection: { items: [], totalCount: 0 },
    });

    const pageInfo = reactive({
       all: { page: 0, hasMore: true },
       draft: { page: 0, hasMore: true },
       pending: { page: 0, hasMore: true },
       published: { page: 0, hasMore: true },
       submit_rejection: { page: 0, hasMore: true },
     });

    const items = computed(() => stateData[state.currentState].items);

    const submittingPosts = ref(new Set());

    const fetchCurrentUser = async () => {
      try {
        const response = await fetch('/api/v1/profiles/current_user', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          currentUser.value = await response.json();
        } else {
          throw new Error('Failed to fetch current user');
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    const fetchItemData = async (stateType, page, perPage) => {
      if (!pageInfo[stateType].hasMore || state.loading) return;

      state.loading = true;
      const url = `/api/v1/profiles/models`;
      const params = new URLSearchParams({
        page: page + 1,
        per_page: perPage,
        kw: state.currentSearch,
        state: stateType === 'all' ? '' : stateType
      });

      try {
        const response = await fetch(`${url}?${params}`);
        if (response.ok) {
          const data = await response.json();

          stateData[stateType].totalCount = data.total_count;

          const newItems = data.data;

          const initialLength = stateData[stateType].items.length;
          categorizeItems(newItems);
          const addedItemsCount = stateData[stateType].items.length - initialLength;

          pageInfo[stateType].page++;
          pageInfo[stateType].hasMore = pageInfo[stateType].page < data.total_pages;

          if (stateData[stateType].items.length < perPage && addedItemsCount < perPage && pageInfo[stateType].hasMore) {
            fetchItemData(stateType, pageInfo[stateType].page, perPage);
          }
        }
      } catch (error) {
        console.error('Error fetching model data:', error);
        pageInfo[stateType].hasMore = false;
      } finally {
        state.loading = false;
        state.initialLoading = false;
      }

    };

    const categorizeItems = (newItems) => {
      newItems.forEach(item => {
        // 更新 all 狀態
        if (!stateData.all.items.some(existingItem => existingItem.id === item.id)) {
          stateData.all.items.push(item);
          stateData.all.totalCount++;
        }
        
        // 更新特定狀態
        if (!stateData[item.state].items.some(existingItem => existingItem.id === item.id)) {
          stateData[item.state].items.push(item);
          stateData[item.state].totalCount++;
        }
      });
    };

    const fetchPostStateTypes = async () => {
      if (!currentUser.value) return;
      state.loading = true;
      try {
        const url = `/api/v1/profiles/${currentUser.value.username}/get_post_state_types`;
        const params = new URLSearchParams({
          kw: state.currentSearch,
        });
        const response = await fetch(`${url}?${params}`);
        if (response.ok) {
          const data = await response.json();
          state.postStateTypes = data.post_state_types || [];
        }
      } catch (error) {
        console.error('Error fetching post state types:', err);
      } finally {
        state.loading = false;
      }
    };

    const scrollToTop = () => {
      const modelContainer = document.querySelector('.model-container');
      if (modelContainer) {
        modelContainer.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };

    const handleSearch = async (keyword) => {
      state.currentSearch = keyword;
      resetAllStates();
      await fetchItemData(state.currentState, 0, state.itemsCount);
      scrollToTop();
      fetchPostStateTypes();
      addScrollListener();
    };

    const handleStateFilter = async (stateType) => {
      if (state.currentState === stateType) return;

      state.currentState = stateType;
      if (stateData[stateType].items.length < state.itemsCount && pageInfo[stateType].hasMore) {
        await fetchItemData(stateType, pageInfo[stateType].page, state.itemsCount);
      }
      scrollToTop();
      addScrollListener();
    };

    const handleSubmitAction = async (postId, oldState) => {
      if (submittingPosts.value.has(postId)) return;
      
      submittingPosts.value.add(postId);
      
      try {
        const response = await fetch(`/profile/models/${postId}/ask_for_submit`, {
          method: 'POST',
          headers: {
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
        });

        const result = await response.json();
        if (response.ok) {
          updatePostInItems(result.post, oldState);
        } else {
          throw new Error(result.message || 'Submission failed');
        }
      } catch (error) {
        console.error('Error submitting post:', error);
      } finally {
        submittingPosts.value.delete(postId);
      }
    };

    const updatePostInItems = (updatedPost, oldState) => {
      if (!updatedPost || !updatedPost.id) {
        console.error('Invalid updated post data');
        return;
      }
      // 更新所有相關狀態
      ['all', oldState, updatedPost.state].forEach(state => {
        const index = stateData[state].items.findIndex(item => item.id === updatedPost.id);
        if (index !== -1) {
          // 如果帖子已存在，更新它
          stateData[state].items.splice(index, 1);
          if (state === 'all' || state === updatedPost.state) {
            stateData[state].items.unshift(updatedPost);
          }
        } else if (state === 'all' || state === updatedPost.state) {
          // 如果帖子不存在且應該顯示在這個狀態，添加它
          stateData[state].items.unshift(updatedPost);
          stateData[state].totalCount++;
        }
      });

      updatePostStateCount(oldState, updatedPost.state);
    };
    
    const handlePostDelete = (deletedPost) => { 
      Object.keys(stateData).forEach(state => {
        const index = stateData[state].items.findIndex(item => item.id === deletedPost.id);
        if (index !== -1) {
          stateData[state].items.splice(index, 1);
          stateData[state].totalCount--;
        }
      });

      updatePostStateCount(deletedPost.state, null);
    };

    const updatePostStateCount = (oldState, newState) => {
      state.postStateTypes = state.postStateTypes.map(type => {
        let count = type.count;
        if (type.type === 'all') {
          if (oldState === null && newState !== null) {
            count++;  // Add new post
          } else if (oldState !== null && newState === null) {
            count--;  // deleted post
          }
          // State changes do not affect the 'all' count
        } else if (type.type === oldState) {
          count--;
        } else if (type.type === newState) {
          count++;
        }

        return { ...type, count: Math.max(0, count) };
      });

      // 更新 stateData 中的 totalCount
      if (oldState !== null && oldState !== 'all') {
        stateData[oldState].totalCount--;
      }
      if (newState !== null && newState !== 'all') {
        stateData[newState].totalCount++;
      }
    };

    const resetAllStates = () => {
      Object.keys(stateData).forEach(key => {
        stateData[key].items = [];
        stateData[key].totalCount = 0;
        pageInfo[key].page = 0;
        pageInfo[key].hasMore = true;
      });
    };

    const addScrollListener = () => {
      const modelContainer = document.querySelector('.model-container')
      if (modelContainer) {
        if (!modelContainer.hasAttribute('data-scroll-listener')) {
          modelContainer.addEventListener('scroll', handleScroll, { passive: true });
          modelContainer.setAttribute('data-scroll-listener', 'true');
        }
      } else {
        console.warn('Model container element not found');
      }
    };

    const handleScroll = debounce((event) => {
      if (!pageInfo[state.currentState].hasMore) return;
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
      if (scrolledPercentage > 30 && !state.loading) {
          fetchItemData(state.currentState, pageInfo[state.currentState].page , state.itemsCount);
        }
    }, 100);

    const checkUrlStateParam = () => {
      const queryParams = new URLSearchParams(window.location.search)
      const urlState = queryParams.get('state');
      const validStates = ['all', 'draft', 'pending', 'published', 'submit_rejection'];
      if (urlState && validStates.includes(urlState)) {
        handleStateFilter(urlState)
      } else {
        handleStateFilter('all')
      }
    };

    onMounted(async () => {
      try {
        await fetchCurrentUser(); 
        if (currentUser.value) {
          await fetchPostStateTypes();
          await fetchItemData('all', 0, state.itemsCount);
          addScrollListener()
          checkUrlStateParam()
        } else {
          console.error('Failed to fetch current user');
        }
      } catch (error) {
        console.error('Error in onMounted:', error);
      }
    });
    


    return {
      items,
      stateData,
      pageInfo,
      currentUser,
      state,
      handleSearch,
      handleStateFilter,
      handleSubmitAction,
      handlePostDelete,
      submittingPosts,
      checkUrlStateParam
    };
  }
});
</script>
