import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import ProfileLibraries from '../../vue/pages/ProfileLibraries.vue';
import ModelSearchForm from "./components/ModelSearchForm.vue";
import ReportPostModal from './components/ReportPostModal.vue';

class ProfileLibrariesComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }
  init() {
    this.app = createApp(ProfileLibraries);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('model-search-form', ModelSearchForm);
    this.app.component('report-post-modal', ReportPostModal);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default ProfileLibrariesComponent;
