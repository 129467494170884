<template>
  <div class="review-search-form flex flex-col lg:flex-row">
    <div class="flex flex-1 flex-col lg:flex-row w-full lg:w-2/3 lg:mb-0 justify-between items-center">
      <div class="flex flex-col w-full justify-between sm:flex-row">
        <div class="relative mt-2 mb-4 sm:mb-0 lg:mt-6 lg:mb-8">
          <div class="search-icon" @click="handleSearch"></div>
          <input 
            v-model="kw" 
            type="search" 
            :placeholder="$t('models.model_search_form.review_title')" 
            class="flex review-search-bar  w-full h-10 bg-grey-4 py-2 px-4 rounded-3xl sm:w-[292px] border border-transparent focus:outline-none focus:border-[#2bdee9] placeholder:text-[16px]"
            @keydown.enter="handleSearch"
            @search="handleSearch">
        </div>
        <div class='comment-order-type pr-2'> 
          <div class="icon brightness-50"></div>
          <input type="radio" 
                  name='comment-order-type' 
                  class='hidden' 
                  id='order_type_latest' 
                  value="latest" 
                  :checked="orderType == 'latest'">
          <label class='text-grey-3' for='order_type_latest' @click="change_review_order_type('latest')">
            {{ $t('post_comments_collection.latest') }}
          </label>
          <span class='text-grey-3'>|</span>
          <input type="radio" 
                  name='comment-order-type' 
                  class='hidden' 
                  id='order_type_positive'
                  value="score_desc" 
                  :checked="orderType == 'score_desc'">
          <label class='text-grey-3' for='order_type_positive' @click="change_review_order_type('score_desc')">
            {{ $t('post_comments_collection.positive') }}
          </label>
          <span class='text-grey-3'>|</span>
          <input type="radio" 
                  name='comment-order-type' 
                  class='hidden' 
                  id='order_type_negative' 
                  value="score_asc" 
                  :checked="orderType == 'score_asc'">
          <label class='text-grey-3' for='order_type_negative' @click="change_review_order_type('score_asc')">
            {{ $t('post_comments_collection.negative') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'ModelSearchForm',
  props: {
    createBtn: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change_order', 'search'],
  setup(props, { emit }) {
    const kw = ref('');
    const orderType = ref('latest');

    const handleSearch = () => {
      emit('search', kw.value.trim());
    };

    const change_review_order_type = (type) => {
      if (orderType.value == type) {
        return
      }
      orderType.value = type
      emit('change_order', type)
    };

    return {
      kw,
      orderType,
      handleSearch,
      change_review_order_type
    };
  }
};
</script>
