export const setWithExpiry = (cacheKey, data, expireTime) => {
  const cacheExpiration = expireTime || 180000; // 毫秒
  const now = new Date();
  data["timestamp"] = now;

  const item = {
    value: data,
    expiry: now.getTime() + cacheExpiration,
  };
  localStorage.setItem(cacheKey, JSON.stringify(item));
}

export const getWithExpiry = (cacheKey) => {
  const itemStr = localStorage.getItem(cacheKey);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(cacheKey);
    return null;
  }
  return item.value;
}