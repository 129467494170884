<template>
  <input type="checkbox" name='coversation_list_opener' :checked="conversationState.profile">

  <div id="inbox-container" class='mx-auto max-w-[860px] h-full rounded-[16px] overflow-hidden'>
    <div id="coversaction-container" class="flex flex-col bg-grey-2">
      <div class="p-2 pl-4 text-16px font-bold leading-8">
        Message
      </div>

      <hr class='m-0 border-grey-3'>

      <div class="coversaction-list flex-1 relative">
        <div class="p-2 pr-1 absolute w-full h-full">
          <template v-if="conversationState.loading && conversationState.list.length == 0">
            <div class="text-center">
              Loading...
            </div>
          </template>
          <template v-else>
            <div :class="['h-full pr-1 overflow-y-auto', conversationState.list.length > 5 ? 'scrollbar' : '']">
              <template v-for="(con_item, idx) in conversationState.list" :key="con_item.sid">
                <hr v-if="idx != 0" class='my-2 border-grey-3'>

                <div :class="['coversaction', con_item.sid === conversationState.sid ? 'current' : '']"
                     @click="switchConversation(con_item.profile.id)">
                  <div :class="['flex gap-x-2', con_item.last_msg ? '' : 'items-center']">
                    <div :class="['h-8 w-8 inline-block rounded-full overflow-hidden', con_item.last_msg ? 'mt-1' : '']">
                      <img v-lazy="con_item.profile.avatar" :alt="con_item.profile.username" class='h-full w-full'/>
                    </div>
                    <div class="flex-1">
                      <div class="text-16px leading-6 font-bold">
                        {{ con_item.profile.username }}
                      </div>
                      <div v-if='con_item.last_msg'
                           class='text-14px break-all line-clamp-1'>
                        {{ con_item.last_msg.body }}
                      </div>
                      <div v-if='con_item.last_msg'
                           class='text-12px text-grey-5'>
                        {{ formatDate(con_item.last_msg.date_created) }}
                      </div>
                    </div>
                  </div>

                  <template v-if="con_item.unread">
                    <span class="coversaction-alert">
                    </span>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div id="message-container" class="flex flex-col bg-[#24272C]">
      <template v-if='conversationState.profile'>
        <div class="profile p-2 bg-grey-2 sm:border-l border-b border-grey-3 flex gap-x-2 cursor-pointer">
          <div class="w-6 h-6 p-1 sm:hidden" @click="backConversationList">
            <img src="../../images/scroll-top.svg" class="w-4 h-4 -rotate-90" />
          </div>
          <div class="flex items-center gap-x-2">
            <div class="inline-block rounded-full overflow-hidden">
              <img :src="conversationState.profile.avatar" :alt="conversationState.profile.username" class='h-8 w-8'/>
            </div>
            <div class="text-16px leading-6 font-bold">
              {{ conversationState.profile.username }}
            </div>
          </div>
        </div>
        <div class="messages-content flex-1 relative sm:border-l border-grey-3">
          <div class="py-2 absolute w-full h-full">
            <div id="message-board"
                 class="h-full flex flex-col gap-y-3 py-2 pl-4 pr-1 mr-1 overflow-y-auto scrollbar"
                 @scroll="messageBoardScroll">
              <div v-for="message in messageState.list" :key="message.index"
                   :class="['message', message.author === profileIdentity ? 'sent' : 'received']">
                <div class="message-content">
                  <div class="body break-word text-left">{{ message.body }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-4 pt-2 sm:border-l border-grey-3">
          <div class="input-container">
            <textarea rows="1" v-model="messageState.newMsg"
                      @input="textareaHight"
                      @keyup.enter="sendMessage"
                      @keyup.backspace="textareaHight"
                      placeholder="輸入訊息...">
            </textarea>

            <template v-if='messageState.newMsg'>
              <button @click="sendMessage">發送</button>
            </template>
            <template v-else>
              <button @click="sendMessage" disabled>發送</button>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="profile leading-8 p-2 bg-grey-2 border-b border-grey-3">
          &nbsp;
        </div>
        <div class="flex-1 flex flex-col items-center justify-center border-l border-grey-3">
          <div class="mb-6">
            <img src="../../images/Chat_Conversation.svg" class="w-12" />
          </div>
          <div class="text-16px font-bold">
            No messages!
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, onUpdated, onUnmounted } from 'vue';
import { Client } from '@twilio/conversations';

export default defineComponent({
  props: ['profile_id'],
  setup(props) {
    const conversationState = reactive({
      loading: false,
      list: [],
      sid: null,
      curr: null,
      profile: null
    });

    const messageState = reactive({
      loading: false,
      list: [],
      newMsg: '',
      moreMsg: true,
      minIndex: null
    });

    // twilio 交談
    const client = ref(null);

    // 使用者相關內容
    const profileToken = ref('')
    const profileIdentity = ref('')

    const fetchInboxData = async () => {
      try {
        const response = await fetch('/api/v1/inbox/twilio_init', {
          method: 'POST',
        });

        if (response.ok) {
          const data = await response.json();

          profileToken.value    = data.token;
          profileIdentity.value = data.identity;
        }
      } catch (error) {
        console.error('Error fetching init data', error);
      }
    };

    const fetchConversationList = async () => {
      // 從 backend db 拉 inbox_conversations cache 起來
      // 拉完後會去 init twilio conversation，並監聽新訊息
      // 只要沒重整，每次只會拉都只會 init diff 的 twilio conversation

      if (conversationState.loading) return;

      conversationState.loading = true

      try {
        const response = await fetch('/api/v1/inbox/conversations');

        if (response.ok) {
          const conversationData = await response.json();

          const diff = conversationData.filter(item2 =>
            !conversationState.list.some(item1 => item1.sid === item2.sid)
          );

          conversationState.list.unshift(...diff)

          await listenConversation()
        }
      } catch (error) {
        console.error('Error fetching conversation list:', error);
      } finally {
        conversationState.loading = false;
      }
    };

    const listenConversation = async () => {
      if (conversationState.list.length == 0) return;

      try {
        for (const data of conversationState.list) {
          if (data.conversation != undefined) continue;

          const con = await client.value.getConversationBySid(data.sid);
          data.conversation = con

          // 監聽新訊息
          con.on('messageAdded', (message) => {
            let msg = {
              index: message.index,
              author: message.author,
              body: message.body,
              date_created: message.dateCreated
            }

            // 新訊息塞到目前的對話框
            // 不是目前的對話框要通知
            if (data.sid == conversationState.sid) {
              messageState.list.push(msg);
            } else {
              conversationUnread(data.sid)
            }

            // 重整排序 + 更新最後訊息
            reorderConversationList(data.sid, msg)

            // 收到目前的對話對象發的訊息，就更新成已讀
            if (data.sid == conversationState.sid && data.profile.identity == message.author) {
              message.updateAttributes({
                status: 'read'
              })
            }
          });
        }
      } catch (error) {
        console.error('Error listen conversation:', error);
      }
    };

    function conversationUnread(conversation_sid) {
      const curr = conversationState.list.find(item => item.sid === conversation_sid)

      if (!curr) return;

      curr.unread = true
    };

    function reorderConversationList(conversation_sid, msg) {
      // 找到目標 conversation
      const curr = conversationState.list.find(item => item.sid === conversation_sid)

      if (!curr) return;
      // 更新最後訊息資訊
      curr.last_msg = msg

      if (conversationState.list[0].sid === conversation_sid) return;
      // 把 conversation 拉到最上面
      const listWoCon = conversationState.list.filter(item => item.sid !== conversation_sid)
      conversationState.list = [curr, ...listWoCon]
    };

    const switchConversation = async (profile_id) => {
      console.log(`switch to ${profile_id}`)

      await startConversation(profile_id);
    };

    const startConversation = async (profile_id) => {
      try {
        // 開始對話會先從已經初始的 conversationList 中找
        // 沒有的話會打去後端建立新的 conversationList，並透過 fetchConversationList 更新

        const curr = conversationState.list.find(item => item.profile.id === profile_id)
        if (curr) {
          conversationState.curr    = curr.conversation;
          conversationState.sid     = curr.sid;
          conversationState.profile = curr.profile;
        } else {
          const formData = new FormData()
          formData.append('profile_id', profile_id)

          const response = await fetch('/api/v1/inbox/conversations', {
            method: 'POST',
            body: formData
          });

          if (response.ok) {
            const data = await response.json();

            conversationState.curr    = await client.value.getConversationBySid(data.sid);
            conversationState.sid     = data.sid;
            conversationState.profile = data.profile;
          }

          await fetchConversationList();
        }

        // 重置 msg state
        messageState.list = []
        messageState.newMsg = ''
        messageState.moreMsg = true
        messageState.minIndex = null

        await loadMessages().then(() => {
          readAllMessages(conversationState.sid)
        });
      } catch (error) {
        console.error('Error fetching user token:', error);
      }
    };

    const readAllMessages = async (conversation_sid) => {
      const curr = conversationState.list.find(item => item.sid === conversation_sid)

      if (!curr?.unread) return;

      const response = await fetch(`/api/v1/inbox/${conversationState.sid}/read_all?`, {
        method: 'POST',
      });

      curr.unread = false
    };

    const sendMessage = async () => {
      if (!messageState.newMsg.trim()) return;

      try {
        await conversationState.curr.sendMessage(messageState.newMsg);
      } catch (error) {
        console.error('Failed to send message:', error);
      } finally {
        messageState.newMsg = '';
      }
    };

    const loadMessages = async () => {
      if (messageState.loading) return;

      messageState.loading = true

      try {
        let params = {}

        if ( messageState.minIndex != null ) {
          params = { before_id: messageState.minIndex }
        }

        const response = await fetch(
          `/api/v1/inbox/${conversationState.sid}/messages?` +
            new URLSearchParams(params)
        )

        if (response.ok) {
          const data = await response.json();

          messageState.moreMsg = data.length > 0

          if ( data.length > 0 ) {
            messageState.minIndex = data[0].index
            messageState.list.unshift(...data)
          }
        }
      } catch (error) {
        console.error('Error loading messages:', error);
      } finally {
        messageState.loading = false
      }
    };

    function backConversationList() {
      conversationState.curr    = null;
      conversationState.sid     = null;
      conversationState.profile = null;
    }

    function messageBoardScroll() {
      const messageBoard = event.target

      if (!messageState.moreMsg) return;
      if (messageBoard.scrollTop !== 0) return;

      const oldHeight = messageBoard.scrollHeight

      loadMessages().then(() => {
        messageBoard.scrollTop = messageBoard.scrollHeight - oldHeight
      });
    };

    function formatDate(date) {
      return new Date(date).toLocaleString();
    };

    function textareaHight() {
      const textarea = event.target
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    function scrollToBottom() {
      const messageBoard = document.getElementById('message-board');

      if (messageBoard) {
        messageBoard.scrollTop = messageBoard.scrollHeight
      }
    };

    onMounted(async () => {
      try {
        await fetchInboxData();

        if (profileToken.value) {
          client.value = new Client(profileToken.value);

          client.value.on('initialized', async () => {
            await fetchConversationList().then(() => {
              if (props.profile_id) {
                switchConversation(props.profile_id)
              }
            });
          });

          client.value.on('conversationJoined', con => {
            fetchConversationList();
          });

          client.value.on('connectionError', (error) => {
            console.error('Twilio connection error:', error);
          });

          client.value.on('tokenExpired', async () => {
            console.log('Token expired, refreshing...');
            await fetchInboxData();
            await client.value.updateToken(profileToken.value);
          });
        }
      } catch (error) {
        console.error('Error in onMounted:', error);
      }
    });

    onUpdated(async () => {
      scrollToBottom()

      window.initUniformSelect()
    });

    onUnmounted(() => {
      if (client.value) {
        client.value.shutdown();
      }
    });

    return {
      conversationState,
      messageState,

      profileIdentity,

      switchConversation,
      sendMessage,

      backConversationList,
      messageBoardScroll,
      formatDate,
      textareaHight
    }
  }
});
</script>
