<template>
  <div class="relative close" ref="navPanel">
    <div class="cursor-pointer" @click="togglePanel">
      <slot name="icon">
        <img src="../../../images/circle_warning.svg" class="w-6 h-6" alt="Warning" />
      </slot>
    </div>

    <div class="nav-dropdown-mask" :class="[panelClass, { hidden: !isOpen }]">
      <div class="nav-dropdown-wrap">
        <div class="nav-dropdown">
          <div class="p-2 text-white space-y-2">
            <slot name="content">
              <div v-if="submitErrors && submitErrors.title" class="text-14px font-bold">
                {{ submitErrors.title }}
              </div>
              <div v-if="submitErrors && submitErrors.content" class="text-12px font-normal">
                {{ submitErrors.content }}
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'NavPanel',
  props: {
    panelClass: {
      type: String,
      default: 'w-[224px]'
    },
    submitErrors: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const isOpen = ref(false);
    const navPanel = ref(null);

    const togglePanel = () => {
      isOpen.value = !isOpen.value;
      navPanel.value.classList.toggle('close');
    };

    const closePanel = (event) => {
      if (navPanel.value && !navPanel.value.contains(event.target)) {
        isOpen.value = false;
        navPanel.value.classList.add('close');
      }
    };

    onMounted(() => {
      document.addEventListener('click', closePanel);
    });

    onUnmounted(() => {
      document.removeEventListener('click', closePanel);
    });

    return {
      isOpen,
      navPanel,
      togglePanel
    };
  }
};
</script>
