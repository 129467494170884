import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import Campaign from '../../vue/pages/Campaign.vue';
import CampaignCard from "./components/CampaignCard.vue";

class CampaignComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }
  init() {
    this.app = createApp(Campaign);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('campaign-card', CampaignCard);

    this.app.mount(this.container);
  }
}

export default CampaignComponent;
