import { createApp, reactive } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import PostItem from './components/PostItem.vue';
import HomePostCollection from './layouts/HomePostCollection.vue';


class HomePostComponent {
  constructor({ container, postFeed, postCollection }) {
    this.app = null;
    this.container = container;
    this.postFeed = postFeed;
    this.postCollection = postCollection;
  }

  // Initialize the component
  init() {
    const props = { postFeed: this.postFeed, postCollection: this.postCollection }
    this.app = createApp(HomePostCollection, props);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });
    
    this.app.component('post-item', PostItem);
    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default HomePostComponent;