import { createApp, reactive } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import PostIndexCollection from './layouts/PostIndexCollection.vue';
import PostItem from "./components/PostItem.vue";
import RequestModal from "./components/RequestModal.vue";
import AlertModal from "./components/AlertModal.vue";

class PostIndexComponent {
  constructor({ container, page_container }) {
    this.app = null;
    this.container = container;
  }

  init() {
    this.app = createApp(PostIndexCollection);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('post-item', PostItem);
    this.app.component('request-modal', RequestModal);
    this.app.component('alert-modal', AlertModal);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default PostIndexComponent;