<template>
  <div class="w-full flex">
    <div class="flex flex-1 flex-col">
      <model-search-form @search="handleSearch" :keyword="state.currentSearch"/>

      <div class="flex flex-1 mt-4 text-14px">
        <div v-if="state.initialLoading" class="flex flex-col flex-1 items-center justify-center">
          <p class="text-16px font-medium text-center">
            {{ $t('models.index.loading') }}
          </p>
        </div>

        <div v-else-if="currentUser && items.length === 0" class="flex flex-col flex-1 items-center justify-center">
          <img src="../../images/library.svg" class="block w-8 mb-2" />
          <p class="text-16px font-medium text-center">
            {{ $t('profiles.libraries.no_purchase') }}
          </p>
        </div>

        <div v-else class="w-full sm:aspect-[5/4] md:aspect-[25/21] lg:aspect-[828/592]">
          <div class="model-container max-h-full h-full overflow-auto scrollbar pr-1 -mr-3 ios-scrollbar"
               ref="modelContainer">
            <div class='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2'>
              <template v-for="variant in items" :key="variant.id">
                <div class='post_variant p-4 bg-grey-4 rounded-8px'>
                  <div class="flex">
                    <div class="flex-1 text-16px font-medium truncate" :title="variant.post.title">
                      {{ variant.post.title }}
                    </div>
                    <div class='variant-more relative'>
                      <input :id="`variant-${variant.id}-more`" type='radio' name='variant-more' class='hidden'>
                      <label :for="`variant-${variant.id}-more`" class='cursor-pointer' @click.prevent="moreBtnClick(variant)">
                        <span class='icon more-icon'></span>
                      </label>

                      <div class='more-content absolute'>
                        <button class='w-full flex items-center' @click.prevent='reportPost(variant)'>
                          <span class='icon report-icon mr-2'></span>
                          <span class='min-w-[32px]'>{{ $t('comment_form.report') }}</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <a :href="variant.post.path" class="relative block w-full rounded-8px overflow-hidden">
                    <div class="image-block aspect-square no-mask no-shadow">
                      <img v-lazy="variant.post.cover_image_src" :alt="variant.post.title" class="image" />
                    </div>
                    <span class="image-mask aspect-square"></span>

                    <div v-if="variant.phrozen_verified"
                        class="flex items-center justify-center w-10 h-4 font-medium absolute top-2 left-2 text-12px bg-[#E60044] rounded-[4px] z-1">
                      <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-0.5">
                        <path d="M5.99941 9.57694C8.22979 9.57694 10.0379 7.76885 10.0379 5.53847C10.0379 3.30808 8.22979 1.5 5.99941 1.5C3.76902 1.5 1.96094 3.30808 1.96094 5.53847C1.96094 7.76885 3.76902 9.57694 5.99941 9.57694Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.9982 7.15363C6.89035 7.15363 7.61359 6.43039 7.61359 5.53824C7.61359 4.64609 6.89035 3.92285 5.9982 3.92285C5.10605 3.92285 4.38281 4.64609 4.38281 5.53824C4.38281 6.43039 5.10605 7.15363 5.9982 7.15363Z" fill="white"/>
                        <path d="M8.15536 11.9996L6.00119 9.8457L3.84766 11.9996" stroke="white" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                      </svg>
                      PV
                    </div>

                    <div v-if='!variant.current_review' class='review-block'>
                      <div class="text-14 font-medium">
                        {{ $t('profiles.libraries.how_was_print') }}
                      </div>
                      <div class="stars flex gap-x-1">
                        <template v-for="rating in [1, 2, 3, 4, 5]" :key="rating">
                          <label class='cursor-pointer'
                            @click.prevent="ratingClick(variant.post.id, rating)"
                            @mouseenter="ratingEnter(rating)"
                            @mouseleave="ratingLeave"
                          >
                            <template v-if="state.ratingStarHover >= rating">
                              <span class="icon star-hover"></span>
                            </template>
                            <template v-else>
                              <span class="icon star-empty"></span>
                            </template>
                          </label>
                        </template>
                      </div>
                    </div>
                  </a>

                  <div class="my-2 text-14px font-bold">
                    {{ variant.name }}
                  </div>
                  <select :name="'post_variant_' + variant.id + '_file_groups'" class="default-input-klass mb-2" data-uniform-select="true">
                    <option v-if="displaySelectAll()" value="all">
                      All
                    </option>
                    <option v-for="file_group in variant.file_groups" :value="file_group.id">
                      {{ file_group.name }}
                    </option>
                  </select>
                  <button class='w-full btn btn-primary' @click="handleDownload(variant)">
                    {{ $t('buttons.download') }}
                  </button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <report-post-modal v-if='reportState.openModal' :reportReason='reportState.reportReason'></report-post-modal>
</template>

<script>

import { defineComponent, ref, reactive, computed, onBeforeMount, onMounted, onUpdated, nextTick} from "vue";
import { reportState } from "../../controllers/vue/stores/postState";
import debounce from 'lodash/debounce';

export default defineComponent({
  setup(props) {
    const currentUser = ref(null);
    const state = reactive({
      items: [],
      loading: false,
      initialLoading: true,
      itemsCount: 12,
      currentSearch: '',
      ratingStarHover: 0
    });

    const pageInfo = reactive({
      page: 0,
      hasMore: true
    });

    const items = computed(() => state.items);

    const fetchCurrentUser = async () => {
      try {
        const response = await fetch('/api/v1/profile/current_user', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          currentUser.value = await response.json();
        } else {
          throw new Error('Failed to fetch current user');
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    const fetchItemData = async (page, perPage) => {
      if (!pageInfo.hasMore || state.loading) return;

      state.loading = true;

      const url = '/api/v1/profile/purchased_variants';
      const params = new URLSearchParams({
        page: page + 1,
        per_page: perPage,
        kw: state.currentSearch
      });

      try {
        const response = await fetch(`${url}?${params}`);

        if (response.ok) {
          const data = await response.json();
          const newItems = data.data;

          state.items.push(...newItems)

          pageInfo.page++;
          pageInfo.hasMore = pageInfo.page < data.pagination.total_pages;

          const initialLength = state.items.length;
          const addedItemsCount = newItems.length;

          if (state.initialLoading && pageInfo.hasMore) {
            fetchItemData(pageInfo.page, perPage);
          }
        }
      } catch (error) {
        console.error('Error fetching model data:', error);
        pageInfo.hasMore = false;
      } finally {
        state.loading = false;
        state.initialLoading = false;
      }
    };

    const downloadItemData = async (post_id, variant_id, file_group_id) => {
      const url = `/posts/${post_id}/download`;
      const params = new URLSearchParams({
        post_variant_id: variant_id,
        file_group_id: file_group_id
      });

      const link = document.createElement('a');
      link.href = `${url}?${params}`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleSearch = async (keyword) => {
      state.initialLoading = true
      state.currentSearch = keyword;
      state.items = []
      pageInfo.page = 0;
      pageInfo.hasMore = true;

      await fetchItemData(0, state.itemsCount);
      scrollToTop();
      addScrollListener();
    };

    const handleDownload = async (variant) => {
      const select = document.querySelector(`select[name='post_variant_${variant.id}_file_groups']`)

      if (select.value == 'all') {
        const file_group_ids = Array.from(select.options).map(option => option.value);
        file_group_ids.shift() // remove default all option

        file_group_ids.forEach((file_group_id, idx) => {
          setTimeout(() => {
            downloadItemData(variant.post.id, variant.id, file_group_id);
          }, idx * 3000);
        });
      } else {
        downloadItemData(variant.post.id, variant.id, select.value)
      }

      if(trackingEvent) {
        trackingEvent.post_download({
          id: variant.post.id,
          title: variant.post.title,
        });
      }
    };

    const moreBtnClick = (variant) => {
      const btn = document.getElementById(`variant-${variant.id}-more`)

      if (btn.checked){
        btn.checked = false
      } else {
        btn.checked = true
      }
    };

    const reportPost = (variant) => {
      document.getElementById(`variant-${variant.id}-more`).checked = false

      reportState.openModal = true
      reportState.targetId = variant.post.id
      reportState.reportKey = null
      reportState.reportReason = null
    };

    const ratingEnter = (value) => {
      state.ratingStarHover = value;
    };

    const ratingLeave = () => {
      state.ratingStarHover = 0;
    };

    const ratingClick = (post_id, stars) => {
      window.location.href = `/posts/${post_id}?#new-review-${stars}`
    };

    const displaySelectAll = () => {
      // 攜帶裝置或是螢幕太小就不顯示下載全部
      const userAgent = window.navigator.userAgent.toLowerCase();
      const mobileKeywords = ['android', 'webos', 'iphone', 'ipad', 'ipod', 'blackberry', 'windows phone']
      const tabletKeywords = ['ipad', 'android 3', 'android 4', 'android 5']

      const isMobile = mobileKeywords.some(keyword => userAgent.includes(keyword))
      const isTablet = tabletKeywords.some(keyword => userAgent.includes(keyword))

      return !(isMobile || isTablet || window.innerWidth < 1024)
    }

    const scrollToTop = () => {
      const modelContainer = document.querySelector('.model-container');
      if (modelContainer) {
        modelContainer.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };

    const addScrollListener = () => {
      if (window.innerWidth < 768) {
        window.addEventListener('scroll', windowBodyHandleScroll);
      } else {
        const modelContainer = document.querySelector('.model-container')

        if (modelContainer && !modelContainer.hasAttribute('data-scroll-listener')) {
          modelContainer.addEventListener('scroll', handleScroll, { passive: true });
          modelContainer.setAttribute('data-scroll-listener', 'true');
        }
      }
    };

    const windowBodyHandleScroll = debounce((event) => {
      const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight - 600;
      const scrolledDistance = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );

      if (scrolledDistance >= scrollableHeight && !state.loading) {
        fetchItemData(pageInfo.page, state.itemsCount);
      }
    }, 100);

    const handleScroll = debounce((event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;

      const scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
      if (scrolledPercentage > 30 && !state.loading) {
        fetchItemData(pageInfo.page, state.itemsCount);
      }
    }, 100);

    onBeforeMount(() => {
      const kw = new URL(window.location).searchParams.get('kw')
      if (kw) {
        state.currentSearch = new String(kw)
      }
    });

    onMounted(async () => {
      try {
        await fetchCurrentUser();
        if (currentUser.value) {
          await fetchItemData(0, state.itemsCount);
          addScrollListener()
        } else {
          console.error('Failed to fetch current user');
        }
      } catch (error) {
        console.error('Error in onMounted:', error);
      }
    });

    onUpdated(async () => {
      window.initUniformSelect()
    })

    return {
      currentUser,
      state,
      reportState,
      items,
      pageInfo,
      handleSearch,
      handleDownload,
      moreBtnClick,
      reportPost,
      ratingEnter,
      ratingLeave,
      ratingClick,
      displaySelectAll
    };
  }
});
</script>
