<template>
  <div class="post-review-item">
    <div class="review review-item-layout sm:items-start relative flex flex-col sm:flex-row items-center w-full py-4  border-gray-30 border-b">
      <a :href="`/posts/${review.post.id}?#review_${review.id}`" class="review-link top-5 right-5 sm:top-3 sm: flex justify-center items-center absolute btn-primary w-8 h-8 rounded-full" >
        <div class="w-5 h-5 object-contain icon link_to pointer"></div>
      </a>
      <div class="post-image w-[21rem] min-w-[21rem] sm:w-[130px] sm:min-w-[130px] sm:h-[130px] sm:min-h-[130px] flex mb-4 sm:mb-0 sm:mr-4">
        <img v-lazy="review.post.cover_image_src" :alt="review.post.title" class="w-full rounded-8px overflow-hidden" />
      </div>
      <div class="post-container flex flex-col w-full px-4 sm:px-0 sm:w-2/3 sm:mr-16 lg:w-[85%]">
        <div class="post-title truncate max-w-[310px] smd:max-w-md lg:max-w-xl text-14px">
          {{ review.post.title }}
        </div>
        <div class="review-container flex flex-col w-full" >
          <div class="review-header flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <div class='review-rating flex items-center my-2 sm:mb-0'>
              <span v-for="rating in 5" :key="rating" class="icon" :class="{'star': review.stars >= rating, 'star-empty': review.stars < rating}"></span>
            </div>
            <div class='reviewer text-grey-1 text-[12px]'>
              By 
              <a :href="`/p/${review.profile.username}/posts`" class="underline" >
                {{ review.profile.username }}
              </a>
              | {{review.created_at}}
            </div>
          </div>
          <div class="review-content text-14px mt-2 mb-4 break-words">
            <span v-if="isTruncated && isMobile">{{ truncatedContent }}</span>
            <span v-else>{{ review.content }}</span>
            <!-- "more" btn -->
            <button v-if="isTruncated && !isExpanded && isMobile" @click="toggleContent" class="text-blue-500">
              ...more
            </button>
            <button v-if="isExpanded && isMobile" @click="toggleContent" class="text-blue-500">
              ...less
            </button>
          </div>
          <template v-if="review.images.length > 0">
            <div class="images flex flex-wrap">
              <!-- max image: 5-->
              <img
                v-for="(image, index) in review.images"
                :key="index"
                :src="image.x1_url"
                class="w-[72px] h-[72px] mr-2 mb-2 rounded-lg"
                :alt="`Image ${index + 1}`"
              />
            </div>
          </template>
        </div>
      </div> 
    </div>
  </div>
</template>
<script>
  import { defineComponent, computed } from "vue";
  import { setupSharedLinks } from '../utils/postItemSharedLinks';
  import Currency from './Currency.vue';

  export default defineComponent({
    data() {
      return {
        isExpanded: false,
        isMobile: false,
        maxLength: 100,
      };
    },
    props: ['review'],
    mounted() {
      this.checkViewportWidth();
      window.addEventListener("resize", this.checkViewportWidth);
    },
    beforeDestroy() {
      // 清理監聽器
      window.removeEventListener("resize", this.checkViewportWidth);
    },
    methods: {
      labelClick(e) {
        let btn = document.getElementById(e.target.dataset.for)

        if (btn.checked) {
          btn.checked = false
        } else {
          btn.checked = true
          setupSharedLinks(e.target)
        }
      },
      truncate(text, length, separator = '...') {
        if (!text) return '';
        if (text.length <= length) return text;
        return text.substring(0, length) + separator;
      },
      async savePost() {
        if(this.is_authenticated){
          fetch(`/api/v1/posts/${this.post.id}/save`, {
            method: 'POST',
          })
          .then(response => response.json())
          .then(data => {
            if (data.status === 'saved') {
              this.saved_post = true;
            } else {
              this.saved_post = false;
            }
            this.saved_count = data.count;
          })
          .catch(error => {
            console.error('Error saving post:', error);
          });
        } else {
          window.location.href = '/users/sign_in';
        }
      },
      toggleContent() {
        this.isExpanded = !this.isExpanded;
      },
      checkViewportWidth() {
        this.isMobile = window.innerWidth < 560;
      },
    },
    computed: {
      isTruncated() {
        return this.review.content.length > this.maxLength && !this.isExpanded;
      },
      truncatedContent() {
        return this.review.content.substring(0, this.maxLength) + '...';
      }
    },
    components: {
      Currency
    } 
  })
</script>

<style>
.max-w-32 {
  max-width: 128px;
}

@media (min-width: 1280px) {
  .max-w-32 {
    max-width: none;
  }
}

.min-w-32 {
  min-width: 128px;
}
</style>