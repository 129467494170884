<template>
  <div id='post-image-full-screen-slide' class='fixed right-0 top-0 w-full h-full'>
    <div class='w-full flex-1 flex items-center'>
      <div class="swiper-container w-full h-full">
        <div class="swiper-wrapper h-full">
          <div v-for="image in this.slideState.images" :key="image.id" class='swiper-slide rounded-xl overflow-hidden flex items-center justify-center'>
            <div class='img h-full' :data-zoom='image.url'
              :style="{ backgroundImage: `url('${image.url}')`, aspectRatio: this.slideState.aspectRatio[0] + '/' + this.slideState.aspectRatio[1] }"></div>
          </div>
        </div>

        <div class="swiper-button-prev">
          <div class='icon swiper-btn w-4 h-4 sm:w-6 sm:h-6 -rotate-90'></div>
        </div>

        <div class="swiper-button-next">
          <div class='icon swiper-btn w-4 h-4 sm:w-6 sm:h-6 rotate-90'></div>
        </div>
      </div>

      <div class="swiper-pagination inline-flex gap-x-2 py-1.5 px-2 items-center rounded-8px text-14px">
        <div class='icon swiper-pagy'></div>
        <span></span>
      </div>

      <span class='icon close-icon cursor-pointer absolute' @click.prevent='closeFullScreenImages'></span>
    </div>

    <div class="swiper-container-thumb w-full">
      <div class="swiper-wrapper">
        <div v-for="image in this.slideState.images" :key="image.id" class='swiper-slide overflow-hidden !w-auto'>
          <img
            class='swiper-lazy'
            :src='image.x1_url'
            :data-src='image.x1_url'
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { state } from "../stores/postState";
import Swiper, { Navigation, Pagination, Lazy, Thumbs } from 'swiper';
import Drift from 'drift-zoom'

export default defineComponent({
  props: ['slideState'],
  data() {
    return {
      state
    };
  },
  mounted() {
    document.querySelector('body').classList.add('overflow-hidden')

    window.addEventListener('keydown', this.handleKeydown);
    this.getCommentImages().then(() => {
      let startIndex = 1

      for (const image of this.slideState.images) {
        if (image.id == this.slideState.startImageId) {
          break;
        } else {
          startIndex += 1
        }
      }

      this.postImageSwiper(startIndex)
    })
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    handleKeydown(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeFullScreenImages();
      }
    },
    closeFullScreenImages() {
      this.slideState.openSlide = false
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    async getCommentImages(){
      try {
        if ( this.slideState.images.length > 0 ) {
          return
        }

        const api_path = '/api/v1' + this.slideState.fetchPath
        const response = await fetch(api_path);

        if (response.ok) {
          const data = await response.json();
          const imagesData = data['data']
          const resizeList = data['resize_lists']

          if (imagesData.length > 0) {
            this.slideState.images = imagesData;
            this.slideState.aspectRatio = Object.values(resizeList)[0];
          }
        }
      } catch (error) {
        this.slideState.images = [];
      }
    },
    postImageSwiper(startIndex = 1) {
      let slideCount = this.slideState.images.length

      var swiperThumbs = new Swiper('#post-image-full-screen-slide .swiper-container-thumb', {
        slidesPerView: 'auto',
        spaceBetween: 16,
        freeMode: true,
        watchSlidesProgress: true,
      });

      var swiper = new Swiper('#post-image-full-screen-slide .swiper-container', {
        modules: [Navigation, Pagination, Lazy, Thumbs],
        direction: 'horizontal',
        lazy: {
          loadPrevNext: true,
        },
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 24,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: swiperThumbs,
        },
        on: {
          init: function(sw){
            const pagy = document.querySelector('#post-image-full-screen-slide .swiper-pagination span');
            sw.slideTo(startIndex, 1, false)

            pagy.innerHTML = String(sw.realIndex + 1).padStart(2, '0') + ' / ' + String(slideCount).padStart(2, '0');
          },
          slideChange: function(sw){
            const pagy = document.querySelector('#post-image-full-screen-slide .swiper-pagination span');

            pagy.innerHTML = String(sw.realIndex + 1).padStart(2, '0') + ' / ' + String(slideCount).padStart(2, '0');
          }
        }
      });

      // 照片放大功能
      for(const image of swiper.el.getElementsByClassName('swiper-wrapper')[0].querySelectorAll('[data-zoom]')) {
        const DriftItem = new Drift(image, {
          containInline: true,
          inlinePane: true,
          zoomFactor: 1.5,
        });

        const handleImageError = (imageUrl) => {
          image.src = imageUrl
          DriftItem.destroy()
        }

        image.addEventListener('error', (event) => {
          handleImageError(event.target.dataset['errorImgUrl'])
        });

        window.addEventListener('keydown', function(event){
          if (event.key === 'Escape' || event.key === 'Esc') {
            DriftItem.destroy()
          }
        });
      }
    }
  }
});
</script>
