import { createApp, reactive } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import ProfilePageModelCollection from './layouts/profile_layouts/ProfilePageModelCollection.vue';
import PostItem from "./components/PostItem.vue";
// import ProfilePostItem from "./components/ProfilePostItem.vue";

class ProfilePageModelComponent {
  constructor({ container, scope }) {
    this.app = null;
    this.container = container;
    this.scope = scope;
  }

  init() {
    this.app = createApp(ProfilePageModelCollection, {
      scope: this.scope
    });
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('post-item', PostItem);
    // this.app.component('profile-post-item', ProfilePostItem);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default ProfilePageModelComponent;