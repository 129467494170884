<template>
  {{ formatIsoToUTC }}
</template>

<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    props: ['isoDate'],
    
    computed: {
      formatIsoToUTC() {
        const date = new Date(this.isoDate);

        const year  = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day   = date.getUTCDate().toString().padStart(2, '0');
        const hours   = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`
      } 
    }
  })
</script>

